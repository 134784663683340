
import './App.css';
import React, { Suspense } from 'react';
import { Divider, Grid, Typography, Stack, Button, Container, MHidd } from '@mui/material';
import LoadingScreen from './components/LoadingScreen';

// assets
const HomePage = React.lazy(() => import('./components/HomePage'));

const SocialButtonStyle = {
  borderRadius: '10rem', 
  borderColor: 'black', 
  color: 'black', 
  padding: '0.8rem 1.6rem', 
  textTransform: 'unset', 
  fontSize: '1.2rem', 
  fontWeight: '400', 
  margin: '0.6rem',
  minWidth: '12rem',
  justifyContent: 'space-between'
}

function App() {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <HomePage />
    </Suspense>
  );
}

export default App;
